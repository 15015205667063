import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { Subtitle, Top2, Section, Row, Column, Text, Caption, Box } from "src/components/styled"
import { uppercase, lowercase } from "src/components/Name"
import PremicesLogo from "src/svg/premices.svg"

const Partners = styled(Box)`
  align-items: center;
  justify-content: space-around;
  text-align: center;
  a {
    min-width: 300px;
    flex: 0 1 auto;
    display: inline-block;
    vertical-align: middle;
    margin: 20px 0;
  }
`
const Limited = styled(Column)`
  max-width: 500px;
  margin-left: 90px;
`

interface DataType {
  splash: { childImageSharp: Media }
  team: { childImageSharp: Media }
  ademe: { childImageSharp: Media }
  refashion: { childImageSharp: Media }
}

const AboutPage: GatsbyPage<DataType> = ({ data }) => {
  return (
    <Layout title="about">
      <Top2>
        <FormattedMessage id="about.title" tagName="h1" />
        <FormattedMessage id="about.text" tagName="p" values={uppercase} />
      </Top2>
      <Section>
        <Image fluid={data.splash.childImageSharp.fluid} />
      </Section>
      <Section>
        <Row>
          <Column>
            <FormattedMessage id="about.origin" tagName={Subtitle} />
            <FormattedMessage id="about.agencies" tagName={Text} values={lowercase} />
            <a href="https://premicesandco.com/">
              <PremicesLogo />
            </a>
            <Text>
              <a href="https://premicesandco.com/">premicesandco.com</a>
            </Text>
          </Column>
          <Limited as="figure">
            <Image fluid={data.team.childImageSharp.fluid} />
            <FormattedMessage id="about.caption" tagName={Caption} />
          </Limited>
        </Row>
      </Section>
      <Top2>
        <FormattedMessage id="about.partners" tagName="h1" />
        <FormattedMessage id="about.partnersText" tagName="p" />
      </Top2>
      <Partners>
        <a href="https://www.ademe.fr/">
          <Image fixed={data.ademe.childImageSharp.fixed} />
        </a>
        <a href="https://refashion.fr/pro/">
          <Image fixed={data.refashion.childImageSharp.fixed} />
        </a>
      </Partners>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    splash: file(relativePath: { eq: "about/splash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    team: file(relativePath: { eq: "about/team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ademe: file(relativePath: { eq: "about/ademe.png" }) {
      childImageSharp {
        fixed(width: 200, quality: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    refashion: file(relativePath: { eq: "about/refashion.png" }) {
      childImageSharp {
        fixed(width: 250, quality: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
